import React from "react";
import Footer from "src/components/Footer_gr";

class Page extends React.Component {
  render() {
    return (
      <div>
        
        <div id="fb-root" />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "(function(d, s, id) {\nvar js, fjs = d.getElementsByTagName(s)[0];\nif (d.getElementById(id)) return;\njs = d.createElement(s); js.id = id;\njs.src = \"//connect.facebook.net/el_GR/sdk.js#xfbml=1&version=v2.6&appId=607297119325946\";\nfjs.parentNode.insertBefore(js, fjs);\n}(document, 'script', 'facebook-jssdk'));\n"
          }}
        />
        <div id="disclosureText">
        </div>
        <div id="page" className="pageProducts">
          <div id="limit">
            <header id="header">
              <div className="mobileHeaderContainer clearfix">
                <a href="/gr/" className="logo">
                  Piz Buin
                </a>
                <button
                  id="btnShowMenu"
                  className="collapse-btn collapse-short-btn right"
                  data-target=".collapse-menu"
                  data-toggle="collapse"
                  type="button"
                />
              </div>
              <nav id="menu" className="collapse-menu collapse-short-menu">
                <ul className="nav-header">
                  <li className="nav-path-selected first has-sub-nav">
                    <a className="es_header" href="/gr/our-products/" target="_self">
                      ΤΑ ΠΡΟΪOΝΤΑ ΜΑΣ
                    </a>
                    <ul>
                      <li>
                        <a href="/gr/our-products/moisturising/">
                          Moisturising
                        </a>
                      </li>
                      <li>
                        <a href="/gr/our-products/hydro-infusion/">
                          Hydro Infusion
                        </a>
                      </li>
                      <li>
                        <a href="/gr/our-products/tan_protect/">
                          Tan &amp; Protect
                        </a>
                      </li>                      
                      <li>
                        <a href="/gr/our-products/aftersun/">After Sun</a>
                      </li>
                      <li>
                        <a href="/gr/our-products/gallery/">Gallery</a>
                      </li>
                    </ul>
                  </li>
                  <li>
                    <a className="es_header" href="/gr/our-heritage/" target="_self">
                      Η ΜAΡΚΑ PIZ BUIN®
                    </a>
                  </li>
                  <li>
                    <a className="es_header" href="/gr/know_the_sun/" target="_self">
                      ΓΝΩΡΙΣΤΕ ΤΟΝ ΗΛΙΟ
                    </a>
                  </li>
               {/*<li>
                    <a className="es_header" href="/gr/Where-Have-You-Been/" target="_self">
                      Where Have You Been 2019
                    </a>
                  </li>*/}
                </ul>
                <ul className="countryNav">
                  <li
                    className="collapsible has-sub-nav"
                    style={{
                      "list-style": "none"
                    }}
                  >
                    <a className="countries" href="#">
                      ΧΩΡΕΣ
                    </a>
                    <ul className="subMenu">
                      <li>
                        <a href="/en/our-products/ultra_light/">INT</a>
                      </li>
                      <li>
                        <a href="/uk/our-products/ultra_light/">UK</a>
                      </li>
                      <li>
                        <a href="/pt/nossos-produtos/ultra_light/">PT</a>
                      </li>
                      <li>
                        <a href="/gr/our-products/ultra_light/">GR</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              </nav>
              <div id="menuExtra" />
            </header>
            <section id="desktop-hero-wrapper" className="product-hero">
              <div id="bgImage">
                <div className="areaInner">
                  <img
                    border="0"
                    className="ccm-image-block"
                    alt
                    src="/assets/images/2017-pizbuin_1680x405px_Ultra_Light_GR.jpg"
                    data-mobile="https://www.pizbuin.com/assets/images/Ultra_Light_960x405px_2016.jpg"
                  />
                </div>
              </div>
            </section>
            <section id="content">
              <div className="products-page innerContent clearfix">
                <div className="products-page__intro">
                  <div className="container">
                    <h1>
                      PIZ BUIN<sup>®</sup> ULTRA LIGHT
                    </h1>
                    <div className="products-page__intro__description">
                      <h3>Εξαιρετικά λεπτόρρευστη υφή</h3>
                      <p>
                        Η λεπτόρρευστη ενυδατική σύνθεση απλώνεται εύκολα,
                        χαρίζοντας εξαιρετικά ελαφριά αίσθηση και ματ
                        αποτέλεσμα. Η μη λιπαρή σύνθεση, που δεν κολλάει,
                        απορροφάται αμέσως χωρίς να αφήνει λευκά σημάδια γεγονός
                        που την καθιστά ιδανική για καθημερινή χρήση.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="products-page__list container clearfix">
                  <div className="products-page__product clearfix">
                    <a name="---ultra-light" />
                    <div className="products-page__product__image">
                      <img src="/assets/images/234f5342f00d96e2a584e18f8e41386b_f279.jpg" />
                    </div>
                    <div className="products-page__product__details">
                      <h3>ΑΝΤΗΛΙΑΚΗ ΚΡΕΜΑ ΠΡΟΣΩΠΟΥ - ULTRA LIGHT</h3>
                      <div className="products-page__product__details__benefits">
                        <h4>ΟΦΕΛΗ</h4>
                        <p>
                          Αυτή η λεπτόρρευστη κρέμα προσώπου συνδυάζει την άμεση
                          και αποτελεσματική προστασία από UVA / UVB ακτινοβολία
                          με μία εξαιρετικά ελαφριά σύνθεση που στεγνώνει
                          αμέσως. Η λεπτόρρευστη ενυδατική σύνθεση απλώνεται
                          εύκολα στο πρόσωπό σας χαρίζοντας εξαιρετικά ελαφριά
                          αίσθηση και ματ αποτέλεσμα. Η μη λιπαρή σύνθεση, που
                          δεν κολλάει, απορροφάται αμέσως χωρίς να αφήνει λευκά
                          σημάδια γεγονός που την καθιστά ιδανική για καθημερινή
                          χρήση. Εμπλουτισμένη με ένα αντιοξειδωτικό* παράγωγο
                          Βιταμίνης Ε , συμβάλλει στην πρόληψη της πρόωρης
                          γήρανσης της επιδερμίδας που προκαλείται από τον ήλιο.
                          <br />
                          Ανθεκτική στον ιδρώτα και το νερό.
                        </p>
                      </div>
                      <div className="products-page__product__details__factors">
                        <h4>ΔΙΑΘΕΣΙΜΟ ΣΕ SPF \n</h4>
                        <span className="factor factor30" />
                      </div>
                      <div className="products-page__product__details__directions">
                        <h4>ΟΔΗΓΙΕΣ ΧΡΗΣΗΣ \n</h4>
                        <p>
                          Απλώστε επαρκή ποσότητα ομοιόμορφα πριν την έκθεση
                          στον ήλιο. Η μείωση της ενδεδειγμένης ποσότητας
                          περιορίζει σημαντικά το επίπεδο προστασίας.
                          Επαναλάβετε συχνά την εφαρμογή, ιδιαίτερα μετά από
                          εφίδρωση, κολύμπι ή σκούπισμα με πετσέτα. Αποφύγετε
                          τον μεσημεριανό ήλιο και την παρατεταμένη έκθεση στον
                          ήλιο ακόμα και με τη χρήση αντηλιακού. Κρατήστε τα
                          βρέφη και τα παιδιά μακριά από την απευθείας έκθεση
                          στην άμεση ακτινοβολία του ήλιου.
                        </p>
                        <p className="footNote">*In vitro test.</p>
                      </div>
                      <div className="products-page__product__details__ingredients">
                        <ul className="ingredientsList">
                          <li>
                            <a href="#" className="toggle">
                              ΣΥΣΤΑΤΙΚΑ &amp; ΤΕΧΝΟΛΟΓΙΕΣ{" "}
                              <span className="toggle__plus">+</span>
                            </a>
                            <ul>
                              <li>
                                Προηγμένο αντηλιακό σύστημα φίλτρων κατά των
                                ακτίνων UVA/UVB PIZ BUIN®
                                <div className="textBlock bottom">
                                  <p>
                                    Ως ειδικοί στην αντηλιακή φροντίδα,
                                    χρησιμοποιούμε καινοτόμες τεχνολογίες για να
                                    εξασφαλίσουμε ότι επιτυγχάνεται η σωστή
                                    ισορροπία μαυρίσματος και προστασίας όταν
                                    απολαμβάνετε τον ήλιο.
                                  </p>
                                  <p>
                                    Κάθε προηγμένο αντηλιακό σύστημα φίλτρων
                                    κατά των ακτίνων UVA/UVB PIZ BUIN
                                    <sup>®</sup> είναι μία ισχυρή τεχνολογία
                                    προστασίας από τον ήλιο που έχει αναπτυχθεί
                                    από την Kenvue. Προσφέρουν
                                    αποτελεσματική, φωτοσταθερή και ευρέως
                                    φάσματος προστασία κατά των ακτίνων UVA και
                                    UVB, που δηλώνει ότι τα αντηλιακά PIZ BUIN
                                    <sup>®</sup> είναι σύμφωνα με τους ισχύοντες
                                    ευρωπαϊκούς κανονισμούς.
                                  </p>
                                  <p>
                                    Όλα τα προηγμένα αντηλιακά συστήματα φίλτρων
                                    κατά των ακτίνων UVA/UVB PIZ BUIN®
                                    συμβάλλουν στην προστασία από την υπεριώδη
                                    ακτινοβολία UVA και UVB.
                                  </p>
                                  <p>
                                    Οι ακτίνες UVB - «οι ακτίνες που προκαλούν
                                    έγκαυμα» - βλάπτουν την επιδερμίδα και είναι
                                    η κύρια αιτία του ηλιακού εγκαύματος.
                                  </p>
                                  <p>
                                    Οι ακτίνες UVA – «ακτίνες γήρανσης» -
                                    εισχωρούν βαθύτερα και καταστρέφουν το
                                    κολλαγόνο και την ελαστίνη, το οποίο μπορεί
                                    να οδηγήσει σε πρόωρη γήρανση του δέρματος
                                    και καρκίνο.
                                  </p>
                                  <p>
                                    Τα προηγμένα αντηλιακά συστήματα φίλτρων
                                    κατά των ακτίνων UVA/UVB PIZ BUIN® περιέχουν
                                    φίλτρα και για τους δύο τύπους ακτίνων (UVA
                                    και UVB) για την προστασία ενάντια στον ήλιο
                                    και τη φθορά του δέρματος μακροπρόθεσμα, ενώ
                                    παράλληλα προστατεύουν από τις πλέον σοβαρές
                                    επιπτώσεις της υπεριώδους ακτινοβολίας UVA.
                                    Καλύπτουν το μεγαλύτερο μέρος του φάσματος
                                    της υπεριώδους ακτινοβολίας UVA / UVB,
                                    προσφέροντας ευρεία προστασία στο δέρμα σας.
                                  </p>
                                  <div> </div>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/d9ecfac1ba5e16cd4dcd7d6f49dc467c_f49.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                            <ul>
                              <li>
                                ΒΙΤΑΜIΝΗ Ε
                                <div className="textBlock bottom">
                                  <p>
                                    Η βιταμίνη Ε είναι ένα ισχυρό φυσικό
                                    αντιοξειδωτικό που βοηθά στην προστασία από
                                    τις ελεύθερες ρίζες, οι οποίες καταστρέφουν
                                    τα κύτταρα και προκαλούν πρόωρη γήρανση του
                                    δέρματος.
                                  </p>
                                  <p>
                                    Η βιταμίνη E περιέχεται στο PIZ BUIN
                                    <sup>®</sup>
                                  </p>
                                  <p>
                                    After Sun Tan Intensifying Moisturising
                                    Lotion.
                                  </p>
                                  <div className="ingredientImageWrapper">
                                    <img src="/assets/images/d5cb67f17c9a6484836ce66200118918_f40.png" />
                                  </div>
                                  <span className="bottomShadow" />
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <nav className="platform-links">
              <div className="container">
                <ul>
                  <li>
                    <a
                      className="first"
                      href="/gr/our-products/moisturising/"
                      target="_self"
                    >
                      Moisturising
                    </a>
                  </li>
                  <li>
                    <a href="/gr/our-products/hydro-infusion/" target="_self">
                      Hydro Infusion
                    </a>
                  </li>
                  <li>
                    <a href="/gr/our-products/tan_protect/" target="_self">
                      Tan &amp; Protect
                    </a>
                  </li>
                  <li>
                    <a href="/gr/our-products/instantglow/" target="_self">
                      Instant Glow
                    </a>
                  </li>
                  <li>
                    <a href="/gr/our-products/aftersun/" target="_self">
                      After Sun
                    </a>
                  </li>
                  <li>
                    <a href="/gr/our-products/gallery/" target="_self">
                      Gallery
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
            <div className="clear">
              <br />
            </div>
            <Footer></Footer>
          </div>
        </div>
        <script src="/assets/js/main.js" />
        <script src="/assets/js/vendor/jquery.fancybox.js" />
        <script src="/assets/js/vendor/jquery.fancybox-media.js" />
        <script src="/assets/js/vendor/iealert.min.js" />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function(){\n$('.products-page__product__details__benefits__more').on('click', function(){\nvar box = $(this).siblings('.products-page__product__details__benefits__hidden');\nif(box.is(':visible')){\nbox.slideUp();\n$(this).text('Διαβάστε περισσότερα');\n} else {\nbox.slideDown();\n$(this).text('Διαβάστε λιγότερα');\n}\n});\n});\n"
          }}
        />
                <div className="clear">
          <br />
        </div>
      </div>
    );
  }
}

export default Page;
